
@import 'src/theme/layout';

.change-password {
  padding: 30px;
  padding-bottom: 15px;

  box-shadow: $box-shadow-card;

  button {
    width: 100%;
  }
}
