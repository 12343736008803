
@import 'src/theme/text';
@import 'src/theme/color';

html {
  box-sizing: border-box;

  font-family: $font-family;
}

#root {
  padding-bottom: 20px;
}

#app {
  min-height: 100vh;
}
