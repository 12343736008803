
@import 'src/theme/layout';

.sign-in {
  padding: 30px;

  box-shadow: $box-shadow-card;

  button {
    width: 100%;
  }
}
