
@import 'src/theme/color';

.container {
  margin: auto;
  width: 480px;

  display: flex;
  flex-direction: column;

  .alert {
    margin: 0 auto;
    height: 80px;
    width: 100%;
    padding: 20px 0;

    text-align: center;
  }
  
  .logo {
    margin-left: -5px;
    margin-top: 10px;

    text-align: center;
  }

  .content {
    margin-top: 25px;
  }
}
